/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-02 14:07:17
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 14:27:54
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'


// 经销商配额申请表
export const listDealerPreOrdersNum = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerPreOrdersNum = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerPreOrdersNum = params => axios({
    url: '/api/base/customer/dealerTemporaryQuotaRecord/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerPreOrdersNum = params => axios({
    url:'/api/dealer_pre_orders_num/system/dealerPreOrdersNum/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerPreOrdersNum = params => axios({
    url: '/api/dealer_pre_orders_num/system/dealerPreOrdersNum/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 门店
export const listStorePreOrdersNum = params => axios({
    url: '/api/base/quota/storePreOrdersNum/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 经销商分配店铺配额列表
export const dealerAllocationQuota = params => axios({
    url: '/api/base/quota/storePreOrdersNum/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


// 店铺分配员工配额列表
export const storeAllocationQuota = params => axios({
    url: '/api/base/quota/storeStaffPreOrdersNum/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 店铺申请临时配额列表
export const storeQuotaList = params => axios({
    url: '/api/base/quota/storeApplicationRecord/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 员工申请临时配额列表
export const staffQuotaList = params => axios({
    url: '/api/base/quota/storeStaffApplicationRecord/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


// 汇总列表
export const employeeSummary = params => axios({
    url: '/api/base/quota/storeStaffApplicationRecord/employeeSummary',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const rollbackStatus = params => axios({
  url:'/api/base/quota/storeStaffApplicationRecord/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const storeRollbackStatus = params => axios({
  url:'/api/base/quota/storeApplicationRecord/rollbackStatus/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const dealerRollbackStatus = params => axios({
    url:'/api/base/customer/dealerTemporaryQuotaRecord/rollbackStatus/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})