<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-02 14:07:17
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-19 10:22:37
-->
<template>
  <a-modal
    :maskClosable="false"
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="600"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="申请数量" prop="temporaryQuota">
        <a-input-number
          v-model="rowData.temporaryQuota"
          placeholder="申请数量"
          :min="1"
          style="width: 100%"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item label="有效期" prop="startingTime" v-if="handle == 'add'">
        <DateRange :startTime.sync="rowData.startingTime" :endTime.sync="rowData.expireDate"></DateRange>
      </a-form-model-item>
      <a-form-model-item label="申请原因" prop="applyForReasons">
        <a-textarea v-model="rowData.applyForReasons" placeholder="申请原因"></a-textarea>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editDealerPreOrdersNum,
  selectByIdDealerPreOrdersNum,
  addDealerPreOrdersNum,
} from '../api/DealerPreOrdersNumApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        temporaryQuota: [{ required: true, message: '请输入申请数量', trigger: 'blur' }],
        applyForReasons: [{ required: true, message: '请输入申请原因', trigger: 'blur' }],
        startingTime: [{ required: true, message: '请选择生效时间', trigger: 'blur' }],
      },
      detail: {},
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.detail = row
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if (this.handle == 'add') {
          this.rowData.dealerId = this.$store.getters.userInfo.dealerId
          const res =
            this.handle === 'add'
              ? await addDealerPreOrdersNum(this.rowData)
              : await editDealerPreOrdersNum(this.rowData)
          if (res.code === 200) {
            this.$notification.success({ message: res.message })
            this.$emit('reload')
            this.visible = false
            this.rowData = {}
          } else {
            this.$notification.error({ message: res.message })
          }
        }

        if (this.handle == 'staff') {
          this.axios.post(`/api/base/quota/storeStaffApplicationRecord/add`, this.rowData).then((res) => {
            this.$notification.success({ message: res.message })
            this.$emit('reload')
            this.visible = false
            this.rowData = {}
          })
        }

        if (this.handle == 'store') {
          this.axios.post(`/api/base/quota/storeApplicationRecord/add`, this.rowData).then((res) => {
            this.$notification.success({ message: res.message })
            this.$emit('reload')
            this.visible = false
            this.rowData = {}
          })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
